import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import Stepper from 'react-stepper-horizontal';
import { toastNotification } from '../../../utils/eventUtils';
import { uploadFile } from '../../../utils/files';

export default function MultiStepForm({
  community_id,
  communityResponse,
  setOpenSetupDialog,
  getcommunity,
  onClose,
  websiteUrl,
  communityName,
}) {
  const [step, setStep] = useState(1);

  const [postData, setPostData] = useState({
    // step1
    file: null,
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [newCommunityText, setNewCommunityText] = useState('');

  useEffect(() => {
    console.log('MultiStepForm postData', postData);
  }, [postData]);

  const stepData = [
    {
      title: 'Step One',
    },
    {
      title: 'Step Two',
    },
    {
      title: 'Step Three',
    },
    {
      title: 'Step Four',
    },
  ];

  const handleUpload = async () => {
    try {
      const url = await uploadFile(
        postData?.file,
        setProgress,
        'community/',
        community_id + '/logo'
      );

      setLoading(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (postData?.file === null) {
      setPostData({ ...postData, file: null });
    }
  }, [postData?.file]);

  const submit = async () => {
    setLoading(true);

    try {
      // to avoiding to upload same file again

      let url = '';
      if (postData?.file) {
        url = await handleUpload();
      }
      console.log('url', url);
      let postDataReq = {
        name: postData?.name || '',
        website: postData?.website || '',
        team: { team: postData?.team || [] },
        id: community_id || '',
        communitybadges: {
          communitybadges: postData?.communitybadges || [],
        },
        settings: {
          logo: url || postData?.pictureUrl || '',
          email: postData?.email || '',
          goals: {
            CurrentRating: postData?.CurrentRating,
            CurrentReviewCount: postData?.CurrentReviewCount,
            GoalNumberofReviews: postData?.GoalNumberofReviews,
            GoalRating: postData?.GoalRating,
            ReviewScoreExpected: postData?.ReviewScoreExpected,
          },
          reviewSettings: {
            goals: {
              CurrentRating: postData?.CurrentRating,
              CurrentReviewCount: postData?.CurrentReviewCount,
              GoalNumberofReviews: postData?.GoalNumberofReviews,
              GoalRating: postData?.GoalRating,
              ReviewScoreExpected: postData?.ReviewScoreExpected,
            },
            googleReviews: postData?.googleReviews || '',
          },
        },
      };

      setPostData({ ...postData, file: null });

      console.log('postDataReq', { postDataReq, postData });

      const res = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
        postDataReq
      );
      await getcommunity();

      newCommunityText
        ? toastNotification(newCommunityText, 'success')
        : toastNotification('Community Updated Successfully', 'success');

      console.log('userUpdated after review TYG: ', res);

      setNewCommunityText('');

      setLoading(false);
    } catch (err) {
      console.log('err', err.response);
      setLoading(false);
    }
  };

  return (
    <div className="w-full ">
      <div className=" w-full flex items-center justify-end pr-2 pt-2">
        <button onClick={onClose} className=" p-2 rounded-full border">
          <AiOutlineClose className=" w-5 h-5" />
        </button>
      </div>

      <div className=" w-full grid grid-cols-1 gap-3 mt-3 px-4">
        <h1 className=" text-3xl font-light w-full">
          Set up community and double your reviews{' '}
        </h1>
        <div className=" flex items-center gap-2">
          <div className=" bg-blue-50 rounded-3xl mt-2 px-2  flex gap-1 w-52 items-center justify-center py-1">
            <Rating name="simple-controlled" />
            <p className="text-xs text-gray-700">out of 5</p>
          </div>
        </div>
      </div>

      <div className=" w-full flex items-center justify-center">
        <Stepper steps={stepData} activeStep={step - 1} />
      </div>
      <div className="my-10">
        {step === 1 && (
          <Step1
            setStep={setStep}
            setPostData={setPostData}
            postData={postData}
            communityResponse={communityResponse}
            communityName={communityName}
            websiteUrl={websiteUrl}
            submit={submit}
            setNewCommunityText={setNewCommunityText}
          />
        )}
        {step === 2 && (
          <Step2
            setStep={setStep}
            setPostData={setPostData}
            postData={postData}
            communityResponse={communityResponse}
          />
        )}
        {step === 3 && (
          <Step3
            setStep={setStep}
            setPostData={setPostData}
            postData={postData}
            communityResponse={communityResponse}
          />
        )}
        {step === 4 && (
          <Step4
            setStep={setStep}
            setPostData={setPostData}
            postData={postData}
            submit={submit}
            loading={loading}
            communityResponse={communityResponse}
          />
        )}
      </div>
    </div>
  );
}

const Step1 = ({
  setStep,
  setPostData,
  postData,
  communityResponse,
  communityName,
  websiteUrl,
  submit,
  setNewCommunityText,
}) => {
  const [data, setData] = useState({
    name: postData?.name,
    email: postData?.email,
    website: postData?.website,
    pictureUrl: postData?.pictureUrl,
    googleReviews: postData?.googleReviews,
  });

  const [newCommunity, setNewCommunity] = useState(false);

  // useEffect(() => {
  // const fetchFunction = async () => {
  //   if (communityResponse) {
  //     setData({
  //       ...data,
  //       name: communityResponse?.name,
  //       email: communityResponse?.settings?.email,
  //       website: communityResponse?.website,
  //       pictureUrl: communityResponse?.settings?.logo,
  //       googleReviews:
  //         communityResponse?.settings?.reviewSettings?.googleReviews,
  //     });
  //   } else {
  //     if (websiteUrl && communityName) {
  //       setData({
  //         ...data,
  //         name: communityName,
  //         website: websiteUrl,
  //       });
  //       await getCommunityImageByAPI(websiteUrl);
  //     }
  //   }
  // };
  // fetchFunction();
  // }, [communityResponse, websiteUrl, communityName]);

  const getCommunityImageByAPI = async () => {
    try {
      const res = await axios.get(
        `https://api.opengraphr.com/v1/og?api_token=zOoUHUX4mLywRUgL3UocGf7cPkyjKbuM4BPZi3wr0AxrjcRcqcX6aaKKIlDB8ms5cXqL0soSsn70FhMm&url=${websiteUrl}/&tailgraph=1`
      );
      // setNewCommunityText(
      //   'You have completed the basic requirements, now you need to fill more requirements.'
      // );
      setData({
        ...data,
        pictureUrl: res?.data?.image,
        name: communityName,
        website: websiteUrl,
      });
      setNewCommunity(true);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (newCommunity) {
      submit();
      setNewCommunity(false);
    }
  }, [newCommunity]);

  const [error, setError] = useState({});
  const [file, setFile] = useState();

  useEffect(() => {
    setPostData({ ...postData, ...data, file });
  }, [data, file]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleNext = () => {
    handleError();
    setStep(2);
  };

  const handleError = () => {
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        setError({ ...error, [key]: true });
      }
    });
  };
  const onSelectImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setData({ ...data, pictureUrl: reader.result });
      });

      reader.readAsDataURL(e.target.files[0]);
      console.log(e.target.files[0]);
      setFile(e.target.files[0]);
      // setFileName(e.target.files[0].name);
    }
  };
  return (
    <div className="mx-10">
      <div className="flex justify-center ">
        <div
          onClick={() => document.getElementById('_upload-file_').click()}
          className="bg-cover h-32 w-40 my-6"
          style={{
            backgroundImage: `url(${
              data.pictureUrl ||
              `https://ui-avatars.com/api/?name=${encodeURI('')}`
            } )`,
          }}
        ></div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Name
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            placeholder="Community Name"
            name="name"
            value={data.name}
            onChange={(e) => handleChange(e)}
          />
          {/*<p className="text-red-500 text-xs italic">*/}
          {/*  Please fill out this field.*/}
          {/*</p>*/}
        </div>
        <div className="w-full md:w-1/2 px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Email
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="Email"
            name="email"
            value={data.email}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="rounded-lg shadow-xl bg-gray-50 lg:w-2/2">
        <div className="m-4 pb-10">
          <label className="inline-block mb-2 text-gray-500">
            Upload Image(jpg,png,svg,jpeg)
          </label>
          <div className="flex items-center justify-center w-full ">
            <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
              <div className="flex flex-col items-center justify-center pt-7">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                    clip-rule="evenodd"
                  />
                </svg>
                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                  Upload a profile picture
                </p>
              </div>
              <input
                onClick={(e) => (e.target.value = '')}
                onChange={onSelectImage}
                type="file"
                accept="image/*"
                className="opacity-0"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Website
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="url"
            placeholder="URL"
            name="website"
            value={data.website}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Direct Link Generator{' '}
            <a
              href="https://www.brightlocal.com/free-local-seo-tools/google-id-and-review-link-generator/"
              target="_blank"
            >
              {' '}
              (Sample Google Review Link Generator)
            </a>
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="url"
            placeholder="URL"
            name="googleReviews"
            value={data.googleReviews}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <button
        onClick={handleNext}
        className="bg-pr hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full cursor-pointer "
      >
        Next
      </button>
    </div>
  );
};
const Step2 = ({ setStep, setPostData, postData, communityResponse }) => {
  const [goals, setgoals] = useState({
    CurrentReviewCount: postData?.CurrentReviewCount,
    CurrentRating: postData?.CurrentRating,
    GoalRating: postData?.GoalRating,
    ReviewScoreExpected: postData?.ReviewScoreExpected,
    GoalNumberofReviews: postData?.GoalNumberofReviews,
  });

  useEffect(() => {
    if (communityResponse) {
      setgoals({
        ...goals,
        CurrentReviewCount:
          communityResponse?.settings?.goals?.CurrentReviewCount,
        CurrentRating: communityResponse?.settings?.goals?.CurrentRating,
        GoalRating: communityResponse?.settings?.goals?.GoalRating,
        ReviewScoreExpected:
          communityResponse?.settings?.goals?.ReviewScoreExpected,
        GoalNumberofReviews:
          communityResponse?.settings?.goals?.GoalNumberofReviews,
      });
    }
  }, [communityResponse]);

  let teamMemberCt = 0;

  function calculateNumberOfReviewsNeededToReachGoal(
    currReviewCt,
    currReviewRating,
    goalRating,
    expectedIndivRating
  ) {
    let goalReviewCt =
      Math.ceil(
        Number(currReviewCt) * Number(currReviewRating) -
          Number(goalRating) * Number(currReviewCt)
      ) /
      (Number(goalRating) - Number(expectedIndivRating));

    console.log('calculateNumberOfReviewsNeededToReachGoal', {
      currReviewCt,
      currReviewRating,
      goalRating,
      expectedIndivRating,
      goalReviewCt,
    });

    return goalReviewCt;
  }

  function displayStars(rating) {
    let stars = '';
    for (let i = 0; i < rating; i++) {
      stars = stars + '⭐';
    }
    return stars;
  }

  useEffect(() => {
    setPostData({
      ...postData,
      ...goals,
      GoalNumberofReviews: calculateNumberOfReviewsNeededToReachGoal(
        goals?.CurrentReviewCount,
        goals?.CurrentRating,
        goals?.GoalRating,
        goals?.ReviewScoreExpected
      ),
    });
  }, [goals]);

  const handleNext = () => {
    setStep(3);
  };

  return (
    <>
      <div className="flex flex-wrap -mx-3 mb-6"></div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Current Review Count
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            placeholder="Current Review Count"
            value={goals?.CurrentReviewCount}
            onChange={(e) => {
              if (Number(e.target.value) || e.target.value == '') {
                setgoals({ ...goals, CurrentReviewCount: e.target.value });
              }
            }}
          />
        </div>
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Current Rating
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            placeholder="Current Rating"
            value={goals?.CurrentRating}
            onChange={(e) => {
              if (
                (Number(e.target.value) && Number(e.target.value) <= 10) ||
                e.target.value == ''
              ) {
                setgoals({ ...goals, CurrentRating: e.target.value });
              }
            }}
          />
        </div>

        <div className="w-full px-3">
          {goals.GoalRating && (
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Goal Rating{' '}
              {goals?.CurrentRating >= goals?.GoalRating && (
                <span className="text-red-500 text-xs italic">
                  Please ensure that Goal Rating > Current Rating
                </span>
              )}
            </label>
          )}

          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            placeholder="Goal Rating"
            value={goals?.GoalRating}
            onChange={(e) => {
              if (
                (Number(e.target.value) && Number(e.target.value) <= 10) ||
                e.target.value == ''
              ) {
                setgoals({ ...goals, GoalRating: e.target.value });
              }
            }}
          />
        </div>

        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            <span>
              {goals?.ReviewScoreExpected && (
                <div className="mt-1">
                  Each score is expected to be at least{' '}
                  {displayStars(goals?.ReviewScoreExpected)}{' '}
                  {goals?.ReviewScoreExpected <= goals?.GoalRating && (
                    <span className=" text-red-500">
                      Please ensure that Expected Review Score &gt; Goal Rating
                    </span>
                  )}
                </div>
              )}
            </span>
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            placeholder="Each review expected to be a least X stars"
            value={goals?.ReviewScoreExpected}
            onChange={(e) => {
              if (
                (Number(e.target.value) && Number(e.target.value) <= 10) ||
                e.target.value == ''
              ) {
                setgoals({ ...goals, ReviewScoreExpected: e.target.value });
              }
            }}
          />
          <h6 className=" text-sm mt-3 mb-1">
            Reviews needed: To get to {goals?.GoalRating} stars, you will need:{' '}
            {calculateNumberOfReviewsNeededToReachGoal(
              goals?.CurrentReviewCount,
              goals?.CurrentRating,
              goals?.GoalRating,
              goals?.ReviewScoreExpected
            )}{' '}
            {displayStars(goals?.ReviewScoreExpected)} reviews <br />
            <br />
            {teamMemberCt > 1 && (
              <span>
                Each of the {teamMemberCt} team members is responsible for{' '}
                {calculateNumberOfReviewsNeededToReachGoal(
                  goals?.CurrentReviewCount,
                  goals?.CurrentRating,
                  goals?.GoalRating,
                  goals?.ReviewScoreExpected
                ) / teamMemberCt}{' '}
                reviews
              </span>
            )}
          </h6>
        </div>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleNext}
          className="bg-pr hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full cursor-pointer "
        >
          Next
        </button>
        <button
          onClick={() => setStep(1)}
          className="bg-transparent hover:bg-pr text-pr font-semibold  py-2 px-4  rounded-full cursor-pointer "
        >
          Back
        </button>
      </div>
    </>
  );
};

const Step3 = ({ setStep, postData, setPostData, communityResponse }) => {
  const badgeInputRef = useRef();
  const [badges, setBadges] = useState(postData?.communitybadges || []);

  useEffect(() => {
    setPostData({ ...postData, communitybadges: badges });
  }, [badges]);

  useEffect(() => {
    setBadges(communityResponse?.communitybadges?.communitybadges || []);
  }, [communityResponse]);

  const addBadges = (value) => {
    if (badges.some((badge) => badge?.name === value)) {
      alert(value + 'is already added');
      return;
    }
    setBadges([...badges, { name: value }]);
    badgeInputRef.current.value = '';
  };

  const removeBadges = (i) => {
    const temp = badges.filter((badge, index) => index !== i);
    if (temp.length === 0) {
      setBadges([]);
    }
    setBadges(temp);
  };

  return (
    <>
      <div className="flex flex-wrap -mx-3 mb-6"></div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Add Badges
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="Add"
            ref={badgeInputRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addBadges(e.target.value);
              }
            }}
          />
        </div>
        <div className="w-full flex items-center gap-3 flex-wrap px-4 mt-3">
          {badges &&
            badges?.map((item, index) => (
              <div
                key={index}
                className=" bg-gray-100 rounded-sm button_hover p-2 flex items-center gap-2"
              >
                <p className=" text-sm text-gray-900">{item.name}</p>

                <p
                  onClick={() => removeBadges(index)}
                  className="  border-l cursor-pointer text-sm show_element bg-gray-600 text-white p-1 rounded-full"
                >
                  <IoMdClose className=" w-4 h-4" />
                </p>
              </div>
            ))}
        </div>
      </div>
      <div className="flex justify-between">
        <button
          onClick={() => setStep(4)}
          className="bg-pr hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full cursor-pointer "
        >
          Next
        </button>
        <button
          onClick={() => setStep(2)}
          className="bg-transparent hover:bg-pr text-pr font-semibold  py-2 px-4   rounded-full cursor-pointer "
        >
          Back
        </button>
      </div>
    </>
  );
};

const Step4 = ({
  setStep,
  submit,
  setPostData,
  postData,
  loading,
  communityResponse,
}) => {
  const [option, setOption] = useState([{ name: '', email: '' }]);

  useEffect(() => {
    setOption(
      communityResponse?.team?.team.length > 0
        ? communityResponse?.team?.team
        : [{ name: '', email: '' }]
    );
  }, [communityResponse]);

  console.log('Team members', communityResponse?.team?.team);

  useEffect(() => {
    setPostData({
      ...postData,
      team: option.filter(({ name, email }) => {
        return name !== '' || email !== '';
      }),
    });
  }, [option]);

  function handleChangeValue(value, index) {
    let newArr = [...option];
    newArr[index].name = value;
    setOption(newArr);
  }

  function handleChangeemail(value, index) {
    let newArr = [...option];
    newArr[index].email = value;
    setOption(newArr);
  }

  function addData() {
    setOption([...option, { name: '', email: '' }]);
  }

  function deleteData(selectedIndex) {
    setOption(option.filter((item, i) => i !== selectedIndex));
  }

  return (
    <>
      <h5 className="font-medium leading-tight text-xl mt-0 mb-4 text-pr px-3">
        Add team members
      </h5>
      {option.map((item, index) => (
        <div className="flex  -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="text"
              placeholder="Username"
              value={item.name}
              onChange={(e) => handleChangeValue(e.target.value.trim(), index)}
            />
            {/*<p className="text-red-500 text-xs italic">*/}
            {/*  Please fill out this field.*/}
            {/*</p>*/}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Email"
              onChange={(e) => handleChangeemail(e.target.value.trim(), index)}
              value={item.email}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            {option.length - 1 === index ? (
              <Fab onClick={() => addData()} color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            ) : (
              <Fab
                onClick={() => deleteData(index)}
                color="primary"
                aria-label="add"
              >
                <DeleteIcon />
              </Fab>
            )}
          </div>
        </div>
      ))}

      <div className="flex justify-between">
        <button
          onClick={() => submit()}
          className="bg-pr hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full cursor-pointer "
        >
          {loading ? 'Loading...' : 'Submit'}
        </button>
        <button
          onClick={() => setStep(3)}
          className="bg-transparent hover:bg-pr text-pr font-semibold  py-2 px-4   rounded-full cursor-pointer "
        >
          Back
        </button>
      </div>
    </>
  );
};
