import Box from '@material-ui/core/Box';
import MatGrid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import firebase from '../../utils/base';
import './signin.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [resetPasswordMessage, setResetPassMessage] = useState(
    <>Enter your email to reset your password</>
  );

  const onPassReset = (e) => {
    e.preventDefault();
    setResetPassMessage(
      <>A magnet is fetching your email address from our records</>
    );

    firebase.auth
      .sendPasswordResetEmail(email)
      .then(function () {
        console.log('email pass reset successful: ', email);
      })
      .catch(function (error) {
        console.log('email pass reset failed: ', error);
      });

    setResetPassMessage(
      <>
        A magnet is fetching your email address from our records. <br /> If an
        email is found on file, a reset password email has been been sent
      </>
    );
  };

  return (
    <div className="ResetPassword">
      <div className="SignIn">
        <a href="#">
          {' '}
          <img
            src="https://assets.website-files.com/60558f1b1e791c307943f510/609163d42e66b49539df4040_tour.video%20logo%20TYG%202%20.png"
            style={{ height: '80px', paddingLeft: '15px', paddingTop: '20px' }}
          />
        </a>
        <br />
        <br />
        <br />
        <br />

        <MatGrid container spacing={1}>
          <Box clone order={{ xs: 3, sm: 2 }}>
            <MatGrid item md={4}>
              <center>
                <img
                  style={{ width: '60%' }}
                  src="https://i.imgur.com/vJ9QNCB.png"
                />
                <br />
                <br />
                <br />
                <h1 style={{ fontWeight: '900' }}> {resetPasswordMessage} </h1>
                {/* <h1 style={{ fontWeight: 900 }}><span class='blue-highlight'> & deliver it perfectly 24/7 </span>
                </h1> */}
                <br />
                <br />
                <br /> <br />
                <br />
                <br />
              </center>
            </MatGrid>
          </Box>
          <Box clone order={{ xs: 2, sm: 3 }}>
            <MatGrid item md={6}>
              <div style={{ width: '80%', marginLeft: '25px' }} id="top">
                <h1 style={{ color: '#262E37', fontWeight: 900 }}>
                  Lost your password?{' '}
                </h1>{' '}
                <br />
                <input
                  type="text"
                  name="login"
                  id="login"
                  tabindex="1"
                  class="text-input"
                  autocorrect="off"
                  autocapitalize="off"
                  placeholder="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  className="button signin w-button"
                  onClick={onPassReset}
                >
                  Reset Password{' '}
                </button>
                <br />
                <br />
                <br />
                <br />
                New to Tour.video?{' '}
                <a href="/signup" class="special-link">
                  {' '}
                  <b> Sign up here </b>
                </a>
                <br />
                <br />
                Remember your password?{' '}
                <a href="/signin" class="special-link-grey">
                  {' '}
                  <b> Sign in</b>
                </a>
                <br />
                <br />
                <br />
                <br />
              </div>
            </MatGrid>
          </Box>
          ß
        </MatGrid>
      </div>
    </div>
  );
};

export default ResetPassword;
