import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import './Settings.css';

// answers = ["option1", "option2",...]
export default function AnswerChoices({ answers, updateAnswers }) {
  return (
    <div>
      <Grid container justify="center" alignItems="center">
        {answers?.map((answer, idx) => (
          <Answer
            key={answer + idx}
            text={answer}
            updateAnswer={(newAnswer, idx) => {
              const temp = answers;
              temp[idx] = newAnswer;
              updateAnswers(temp);
            }}
            deleteAnswer={() => {
              const temp = answers.filter((answer, i) => i !== idx);
              updateAnswers(temp);
            }}
          />
        ))}

        <Grid item xs={10}>
          <button
            onClick={() => {
              const temp = answers || [];
              temp.push('');
              updateAnswers(temp);
            }}
          >
            + Add answer choice
          </button>
        </Grid>
      </Grid>
    </div>
  );
}

function Answer({ text, updateAnswer, deleteAnswer }) {
  return (
    <Grid item xs={12} sm={10}>
      <div className="form-group flex flex-col">
        <DebouncedTextField
          id="answer"
          placeholder="Answer"
          variant="outlined"
          value={text}
          onChange={(newValue) => updateAnswer(newValue)}
          size="small"
          style={{ width: 'calc(100%)' }}
          InputProps={{
            endAdornment: (
              <IconButton color="secondary" onClick={deleteAnswer}>
                <DeleteIcon style={{ fontSize: 18 }} />
              </IconButton>
            ),
          }}
        />

        <br />
      </div>
    </Grid>
  );
}
